import { CaixaPostalService } from 'src/app/services/caixa-postal.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2';
import { RespostaComunicado } from '../../common/interface/respostaComunicado';
import * as dayjs from "dayjs";
import { ModoEscuroService } from 'src/app/services/modoEscuro.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-caixa-postal-comunicado',
  templateUrl: './caixa-postal-comunicado.component.html',
  styleUrls: ['./caixa-postal-comunicado.component.scss']
})
export class CaixaPostalComunicadoComponent implements OnInit {

  comunicado : any;
  idComunicado : any;
  comunicados: any;
  seqProcurador: number;
  loading : boolean = false;
  dadosUsuario : any;
  objUsuario : any;
  anexos: any;
  temAnexo: boolean;
  anexosTratados: any;
  pathArquivo: Object = {};
  arquivoConvertido: any;
  responder: boolean = false;
  respostaComunicado: string = '';
  permiteResposta: boolean;
  dataAtual: Date = new Date();
  file: File;
  files: any = [];
  fileName: string;
  base64File: any = [];
  modalNomearResponsavel: boolean = false;
  exibirCamposNomear: boolean = false;
  cpfNomear: string = '';
  nomeNomear: string = '';
  listaNomeacoes: Array<any> = [];
  seqCadastroNomeacao: string = '';
  existeNomeado: boolean = false;
  modoEscuro: boolean = false;
  mensagem: any;
  historico: any;
  modalHistorico: boolean = false;
  src: any;
  botaoDesabilitado: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private caixaPostalService : CaixaPostalService,
    private route: ActivatedRoute, 
    private router: Router,
    private modoEscuroService: ModoEscuroService
  ) { }

  ngOnInit(): void {
    this.dadosUsuario = localStorage.getItem('contribuinteEmUso');
    this.objUsuario = JSON.parse(this.dadosUsuario);
    this.idComunicado = this.route.snapshot.paramMap.get('comunicado');
    this.seqProcurador = this.objUsuario.seqProcuracao ? this.objUsuario.seqProcuracao : 0;
    this.loading = true;
    this.caixaPostalService.getComunicado(this.objUsuario.codigo, this.idComunicado).subscribe(
      res => {
        this.comunicados = res;
        if (this.comunicados.length > 0) {
          const comunicadoAtual = this.comunicados[0]; 
          this.botaoDesabilitado = comunicadoAtual.cpfCnpj !== this.objUsuario.cadastro.cpfCnpj;
        }
        for (let i = 0; i < this.comunicados.length; i++) {          
            this.comunicados[i].txtMensagem = this.sanitizer.bypassSecurityTrustHtml(this.comunicados[i].txtMensagem);
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu um erro ao carregar o comunicado.'
        })
        this.router.navigate(['/caixa-postal']);
      },
      () => {
        this.loading = false;
        let datPrazoResposta = dayjs(this.comunicados[0].datPrazoResposta);
        datPrazoResposta = datPrazoResposta.add(23, 'hour');
        datPrazoResposta = datPrazoResposta.add(59, 'minute');
        datPrazoResposta < dayjs(this.dataAtual) ? this.permiteResposta = false : this.permiteResposta = true;
        this.caixaPostalService.gerarLeitura(this.comunicados[0].seqCaixaPostal, this.objUsuario.cadastro.codigo, this.seqProcurador).subscribe((res) => {
          if(res !== null && res !== "") {
            Swal.fire({
              icon: 'info',
              text: res
            });
          }
        });
      }
    )

    this.modoEscuroService.modoEscuro$.subscribe(modo => {
      this.modoEscuro = modo;
    });
  }

  converterBase64(base64: any) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    this.arquivoConvertido = bytes.buffer;
  }

  permitirRespostaToggle() {
    if (this.responder == false) {
      this.responder = true;
    } else {
      this.respostaComunicado = '';
      this.responder = false;
    }
  }

  downloadAnexo(comunicado) {
    this.loading = true;
    this.pathArquivo = {
      "caminhoUrl": comunicado.dscUriAlfresco
    }
    this.caixaPostalService.getAnexos(this.pathArquivo).subscribe(
      res => {
        this.anexosTratados = res;
      },
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 500) {
            this.loading = false;
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Não foi possível encontrar o arquivo especificado!'
            });
          }
        }
      },
      () => {
        this.loading = false;
        this.converterBase64(this.anexosTratados.data.arquivo);
        var blob = new Blob([this.arquivoConvertido], { type: 'application/zip' });
        FileSaver.saveAs(blob, this.anexosTratados.data.nomeArquivo);
      }
    )
  }

  responderComunicado() {
    this.loading = true;

    const isNomeacao = this.comunicados[0].isNomeacao;
    const seqContribuinte = isNomeacao ? this.comunicados[0].seqContribuinte : this.objUsuario.codigo;
    const seqProcurador = isNomeacao ? this.comunicados[0].nomeadoCaixaPostal?.seqProcurador : (this.seqProcurador ? this.seqProcurador : null);

    let obj: RespostaComunicado = {
      fkSeqCaixaPostal: this.comunicados[0].seqCaixaPostal,
      datPrazoResposta: this.comunicados[0].datPrazoResposta ? dayjs(this.comunicados[0].datPrazoResposta).format('YYYY-MM-DD') : null,
      numOrigem: this.comunicados[0].numOrigem,
      seqContribuinte: seqContribuinte,
      seqCadastro: this.objUsuario.cadastro.codigo,
      seqTipoComunicado: this.comunicados[0].tipoComunicado.seqTipoComunicado,
      dscAssunto: this.comunicados[0].dscAssunto,
      txtMensagem: this.respostaComunicado,
      temAnexo: false,
      seqProcurador: seqProcurador,
      anexos: []
    };
    if (this.files.length > 0) {
      obj.temAnexo = true;
      for (let i = 0; i < this.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(this.files[i]);
        reader.onload = () => {
          this.base64File.push(reader.result);
        }
        setTimeout(() => {
          this.base64File[i] = this.base64File[i].split(',')[1];
          let arquivo = {
            dscNomeAnexo: this.files[i].name,
            arquivo: this.base64File[i]
          }
          obj.anexos.push(arquivo);
          if (i == this.files.length - 1) {
            this.chamadaRespostaComunicado(obj);
          }
        }, 500);  
      }      
    } else {
      obj.temAnexo = false;
      this.chamadaRespostaComunicado(obj)
    }
  }

  chamadaRespostaComunicado(obj) {
    this.caixaPostalService.respostaComunicado(obj).subscribe(
      res => {
        this.loading = false;
      },
      err => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: 'Ocorreu um erro ao responder o comunicado!'
        })
        this.router.navigate(['caixa-postal']);
      },
      () => {
        this.loading = false;
        Swal.fire({
          icon: 'success',
          text: 'Comunicação respondida com sucesso!'
        });
        this.router.navigate(['/caixa-postal'])
      }
    )
  }

  anexo(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files[i]);
    }
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  deletarArquivo(item) {
    this.files.splice(item, 1);
  }

  abrirNomearResponsavel() {
    this.modalNomearResponsavel = true;
    this.getNomeacoes();
  }

  fecharNomearResponsavel() {
    this.modalNomearResponsavel = false;
  }

  exibirFormNomear() {
    this.exibirCamposNomear = true;
    this.modalNomearResponsavel = false;
  }

  fecharFormNomear() {
    this.exibirCamposNomear = false;
    this.modalNomearResponsavel = true;
    this.cpfNomear = "";
    this.nomeNomear = "";
  }

  getNomeacoes() {
    this.loading = true;
    this.listaNomeacoes = [];
    this.caixaPostalService.getNomeacoes(this.idComunicado).subscribe((res: any) => {
      this.listaNomeacoes = res;
      this.seqCadastroNomeacao = res.codigo;

      this.listaNomeacoes.forEach(res => {
        if(res.dataRemocao === null) {
          this.existeNomeado = true;
        }
      });

      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  validarCpf() {
    if(this.cpfNomear.length == 11) {
      this.loading = true;
      this.caixaPostalService.validarCpf(this.cpfNomear).subscribe((res: any) => {
        this.nomeNomear = res.nome;
        this.seqCadastroNomeacao = res.codigo;
        this.loading = false;
      }, error => {
        Swal.fire({
          icon: 'warning',
          text: 'Usuário não cadastrado. É necessário que o usuário esteja cadastrado e que tenha aderido à adesão do DEC.'
        });
        this.nomeNomear = '';
        this.loading = false;
      });
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Usuário não cadastrado. É necessário que o usuário esteja cadastrado e que tenha aderido à adesão do DEC.'
      });
    }
  }

  nomearResponsavel() {
    this.loading = true;
    const obj = {
      seqCadastro: this.seqCadastroNomeacao,
      seqCaixaPostal: this.idComunicado,
      status: 0
    }

    this.caixaPostalService.nomearResponsavel(obj).subscribe((res: any) => {
      Swal.fire({
        icon: 'success',
        text: 'Nomeação adicionada com sucesso!'
      }).then(() => {
        this.getNomeacoes();
        this.fecharFormNomear();
      });
    }, error => {
      if(error.error.mensagem == "Esta caixa postal já tem uma nomeação ativa para este cadastro.") {
        Swal.fire({
          icon: 'warning',
          text: 'Este comunicado já tem uma nomeação ativa para este C.P.F.'
        });
        this.fecharFormNomear();
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Não é possível nomear o próprio usuário como responsável pelo comunicado. Por favor, selecione outro usuário para a nomeação.'
        });
        this.fecharFormNomear();
      }
      
      this.loading = false;
    });
  }

  removerNomeacao(item) {
    Swal.fire({
      icon: 'question',
      text: 'Confirma remover a nomeação?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      showConfirmButton: true,
      confirmButtonText: 'Sim'
    }).then((result) => {
      if(result.isConfirmed) {
        this.loading = true;
        const obj = {
          seqNomeadoCaixaPostal: item.seqNomeadoCaixaPostal,
          status: 1
        }
    
        this.caixaPostalService.nomearResponsavel(obj).subscribe((res: any) => {
          this.getNomeacoes();
        }, error => {
          this.loading = false;
        });
      }
    });
  }

  baixarHistorico() {
    this.loading = true;
    var blob = new Blob([this.src], { type: 'application/pdf' });
    FileSaver.saveAs(blob, 'historico');
    this.loading = false;
  }

  visualizarHistorico() {
    this.loading = true;
    this.caixaPostalService.getHistorico(this.comunicados[0].seqContribuinte, this.comunicados[0].seqCaixaPostal).subscribe(
      res => {
        this.historico = res.arquivo;
      },
      err => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu uma falha de comunicação. Tente novamente mais tarde.'
        })
      },
      () => {
        var binaryString = atob(this.historico);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        this.src = bytes.buffer;
        this.modalHistorico = true;
        this.pdfCarregado();
      }
    )
  }

  pdfCarregado() {
    this.loading = false;
  }
}
